import { lazy } from 'react'

const Attendance: Array<any> = [
  {
    path: '/salary',
    meta: {
      title: '薪酬'
    },
    children: [
      {
        path: '/salary/payroll',
        element: lazy(
          () => import(/* webpackChunkName: "salary/payroll" */ '@/pages/salary/payroll')
        ),
        meta: {
          requiresAuth: true,
          title: '工资单',
          key: 'payroll'
        }
      }
    ]
  }
]

export default Attendance
