import SvgIcon from '../SvgIcon'

export const TabItems: any[] = [
  {
    key: 'home',
    title: '首页',
    icon: (active: boolean) => <SvgIcon name={'home' + (active ? '-active' : '')} />
  },
  {
    key: 'app',
    title: '应用',
    icon: (active: boolean) => <SvgIcon name={'app' + (active ? '-active' : '')} />
  },
  {
    key: 'mine',
    title: '我的',
    icon: (active: boolean) => <SvgIcon name={'mine' + (active ? '-active' : '')} />
  }
]

export const TabActive: any = {
  '/': 'home',
  '/home': 'home',
  '/apply/manage': 'app',
  '/my': 'mine'
}

export const TabPath: any = Object.entries(TabActive).reduce((prev: any, [key, value]: any) => {
  prev[value] = key
  return prev
}, {})
