import { lazy } from 'react'

const Attendance: Array<any> = [
  {
    path: '/notify',
    meta: {
      title: '消息'
    },
    children: [
      {
        path: '/notify/approve',
        element: lazy(
          () => import(/* webpackChunkName: "notify/status" */ '@/pages/notifys/approve')
        ),
        meta: {
          requiresAuth: false,
          title: '消息通知',
          key: 'approve'
        }
      },
      {
        path: '/notify/status',
        element: lazy(
          () => import(/* webpackChunkName: "notify/status" */ '@/pages/notifys/status')
        ),
        meta: {
          requiresAuth: true,
          title: '消息通知',
          key: 'status'
        }
      },
      {
        path: '/notify/view',
        element: lazy(() => import(/* webpackChunkName: "notify/view" */ '@/pages/notifys/view')),
        meta: {
          requiresAuth: true,
          title: '消息通知',
          key: 'view'
        }
      },
      {
        path: '/notify/commonSettings',
        element: lazy(
          () =>
            import(/* webpackChunkName: "notify/commonSettings" */ '@/pages/notifys/commonSettings')
        ),
        meta: {
          requiresAuth: true,
          title: '常用设置',
          key: 'commonSettings'
        }
      }
    ]
  }
]

export default Attendance
