import { RouteObject } from '@/router/types'

import lazyLoad from '../utils/lazyLoad'

const Points: Array<RouteObject> = [
  {
    path: '/schedule',
    meta: {
      title: '排班'
    },
    children: [
      {
        path: '/schedule/advanced',
        element: lazyLoad(
          () => import(/* webpackChunkName: "schedule/advanced" */ '@/pages/schedule/advanced')
        ),
        meta: {
          requiresAuth: true,
          title: '移动排班',
          key: 'scheduleAdvanced'
        }
      },
      {
        path: '/schedule/team',
        element: lazyLoad(
          () => import(/* webpackChunkName: "schedule/team" */ '@/pages/schedule/team')
        ),
        meta: {
          requiresAuth: true,
          title: '团队排班',
          key: 'team'
        }
      },
      {
        path: '/schedule/my',
        element: lazyLoad(
          () => import(/* webpackChunkName: "schedule/my" */ '@/pages/schedule/my')
        ),
        meta: {
          requiresAuth: true,
          title: '我的排班',
          key: 'MySchedule'
        }
      }
    ]
  }
]

export default Points
