import { lazy } from 'react'

import { RouteObject } from '@/router/types'

const Attendance: Array<any> = [
  {
    path: '/attendance',
    meta: {
      title: '考勤管理'
    },
    children: [
      {
        path: '/attendance/teamMonthly',
        element: lazy(
          () =>
            import(
              /* webpackChunkName: "attendance/teamMonthly" */ '@/pages/attendance/teamMonthly'
            )
        ),
        meta: {
          requiresAuth: true,
          cacheSource: ['teamMonthly'],
          keepAlive: true,
          title: '团队月考勤',
          key: 'teamMonthly'
        }
      },
      {
        path: '/attendance/teamMonthly/detail',
        element: lazy(
          () =>
            import(
              /* webpackChunkName: "attendance/teamMonthly/detail" */ '@/pages/attendance/teamMonthly/detail'
            )
        ),
        meta: {
          requiresAuth: true,
          cacheSource: ['teamMonthly'],
          title: '员工月考勤',
          key: 'teamMonthlyDetail'
        }
      },
      {
        path: '/attendance/teamDaily',
        element: lazy(
          () =>
            import(/* webpackChunkName: "attendance/teamDaily" */ '@/pages/attendance/teamDaily')
        ),
        meta: {
          requiresAuth: true,
          cacheSource: ['teamDaily'],
          title: '团队日考勤',
          keepAlive: true,
          key: 'teamDaily'
        }
      },
      {
        path: '/attendance/teamDaily/detail',
        element: lazy(
          () =>
            import(
              /* webpackChunkName: "attendance/teamDaily/detail" */ '@/pages/attendance/teamDaily/detail'
            )
        ),
        meta: {
          requiresAuth: true,
          title: '团队日考勤',
          keepAlive: true,
          cacheSource: ['teamDaily', 'teamDailyDetail'],
          key: 'teamDailyDetail'
        }
      },
      {
        path: '/attendance/teamDaily/schedule',
        element: lazy(
          () =>
            import(
              /* webpackChunkName: "attendance/teamDaily/schedule" */ '@/pages/attendance/teamDaily/schedule'
            )
        ),
        meta: {
          requiresAuth: true,
          title: '添加班次',
          cacheSource: ['teamDaily', 'teamDailyDetail'],
          key: 'teamDailySchedule'
        }
      },
      {
        path: '/attendance/staffDaily',
        element: lazy(
          () =>
            import(/* webpackChunkName: "attendance/staffDaily" */ '@/pages/attendance/staffDaily')
        ),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          cacheSource: ['staffDaily'],
          title: '考勤日历',
          key: 'staffDaily'
        }
      },
      {
        path: '/attendance/attencount',
        element: lazy(
          () =>
            import(/* webpackChunkName: "attendance/attencount" */ '@/pages/attendance/attencount')
        ),
        meta: {
          requiresAuth: true,
          title: '考勤汇总',
          key: 'attencount'
        }
      }
    ]
  }
]

export default Attendance
