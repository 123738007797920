import { TabBar } from 'antd-mobile'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { TabItems, TabPath } from './const'
import './index.less'
/**
 * tabbar 底部菜单
 */
export default () => {
  const location = useLocation()
  const navigate = useNavigate()
  const activeKey =
    {
      '/': 'home',
      '/home': 'home',
      '/apply/manage': 'app',
      '/my': 'mine'
    }[location.pathname] || 'home'
  const isIOS =
    navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
    window.screen.height >= 812 &&
    !navigator.userAgent.match(/Feishu|Lark/)
      ? ' isIOS'
      : ''

  return (
    <>
      <Outlet />
      <div className={`app-tabbar-blank${isIOS}`} />
      <div className={`app-tabbar-wrapper${isIOS}`}>
        <TabBar
          safeArea
          activeKey={activeKey}
          onChange={(key: string) => navigate(TabPath[key], { replace: true })}
        >
          {TabItems.map((item: any) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </>
  )
}
