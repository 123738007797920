import { Loading, SafeArea } from 'antd-mobile'
import React, { useEffect, useRef, Suspense, lazy, useState, startTransition } from 'react'
import { KeepAlive, useAliveController } from 'react-activation'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import HTabBar from '@/components/HTabBar'
import { APP_NAME } from '@/config'
import useAuthLoad from '@/hooks/useAuthLoad'
import routerArray from '@/router/modules'

import { isNotEmptyArray } from '@/utils'
import { bridge } from '@/utils/bridge'
import { searchRoute } from '@/utils/tools'

/**
 * 页面keepAlive使用,
 * 1. 缓存的页面，路由meta中配置 keepAlive: true， key（确保唯一）
 * 2. 列表页面配置 keepAlive: true, 当前页面和跳转的详情页面都需配置 cacheSource: ['列表页面的key']
 * 3. 若详情页（有跳下一个页面）需要缓存 则详情页 keepAlive: true, cacheSource: ['列表页面的key', '详情页的key']
 * 4. useActivate 处理回到缓存页面
 */

// * 处理路由
export const rootRouter = [
  {
    path: '/',
    title: APP_NAME,
    element: <HTabBar />,
    children: [
      {
        path: '/',
        title: APP_NAME,
        element: lazy(() => import(/* webpackChunkName: "home" */ '@/pages/home'))
      }
    ]
  },
  {
    path: '/home',
    title: '首页',
    element: <HTabBar />,
    children: [
      {
        path: '/home',
        title: '首页',
        element: lazy(() => import(/* webpackChunkName: "home" */ '@/pages/home'))
      }
    ]
  },
  {
    path: '/apply',
    title: '应用',
    element: <HTabBar />,
    children: [
      {
        path: '/apply/manage',
        title: '应用',
        element: lazy(() => import(/* webpackChunkName: "apply/manage" */ '@/pages/apply/manage'))
      }
    ]
  },
  {
    path: '/my',
    title: '我的',
    element: <HTabBar />,
    children: [
      {
        path: '/my',
        title: '我的',
        element: lazy(() => import(/* webpackChunkName: "my" */ '@/pages/my'))
      }
    ]
  },
  {
    path: '/login',
    title: '登录',
    element: lazy(() => import(/* webpackChunkName: "login" */ '@/pages/login'))
  },
  {
    path: '/clock',
    title: '打卡',
    element: lazy(() => import(/* webpackChunkName: "clock" */ '@/pages/clock')),
    meta: {
      requiresAuth: true,
      title: '打卡',
      key: 'clock'
    }
  },
  ...routerArray,
  {
    path: '*',
    element: <Navigate to='/my/blankPage' replace />
  }
]

function usePreviousRoute() {
  const location = useLocation()
  const [previousPath, setPreviousPath] = useState<any>(null)
  const currentPathRef = useRef(location.pathname)

  useEffect(() => {
    setPreviousPath(currentPathRef.current)
    currentPathRef.current = location.pathname
  }, [location])

  return currentPathRef.current
}

const isLazyComponent = (Component: any) => {
  return (
    typeof Component === 'object' &&
    Component !== null &&
    Component.$$typeof === Symbol.for('react.lazy')
  )
}

const generateRoute = (routesConfig: any[], fromPath: string) => {
  return routesConfig.map((routeItem: any) => {
    const { path, element: Element, meta } = routeItem
    const { key: cacheKey, keepAlive } = meta || {}
    let children: any[] = []

    const wrappedElement = (() => {
      if (!Element) {
        return null
      }

      // 普通组件
      if (!isLazyComponent(Element)) {
        return Element
      }

      return keepAlive ? (
        <KeepAlive when={true} id={path} cacheKey={path} name={cacheKey}>
          <Suspense fallback={<Loading className='init-loading' />}>
            <Element />
            <SafeArea position='bottom' />
          </Suspense>
        </KeepAlive>
      ) : (
        <Suspense fallback={<Loading className='init-loading' />}>
          <Element />
          <SafeArea position='bottom' />
        </Suspense>
      )
    })()

    if (isNotEmptyArray(routeItem.children)) {
      children = generateRoute(routeItem.children, fromPath)
    }

    return { ...routeItem, children, element: wrappedElement }
  })
}

export default () => {
  console.log('init router')
  useAuthLoad()
  const location = useLocation()
  const toPath = location.pathname
  const fromPath = usePreviousRoute()
  const { clear, drop, getCachingNodes } = useAliveController()

  const dealPageCache = (arr?: string[]) => {
    const cachedNames = getCachingNodes().map((item) => item.name) as string[]
    // 没有缓存则不处理
    if (!isNotEmptyArray(cachedNames)) {
      return
    }
    console.log(`缓存:从页面${fromPath}到页面${toPath}`, cachedNames)
    if (!isNotEmptyArray(arr)) {
      console.log('缓存:清除所有缓存')
      return clear()
    }
    const dropNames = cachedNames.filter((name) => !arr?.includes(name)) as string[]
    console.log('缓存:现有缓存=', cachedNames, '缓存:要清除的缓存=', dropNames)
    isNotEmptyArray(dropNames) && dropNames.forEach(drop)
  }

  useEffect(() => {
    const { meta: toMeta = {} } = searchRoute(toPath, rootRouter)
    document.title = toMeta?.title || APP_NAME

    // 钉钉
    if (bridge.isDd) {
      bridge.setTitle({ title: document.title })
    }

    // 延迟清除页面缓存，避免跳转清除页面事件失效
    setTimeout(() => {
      dealPageCache(toMeta?.cacheSource)
    }, 0)
  }, [location.pathname])

  const routes = useRoutes(generateRoute(rootRouter, fromPath))
  return routes
}
