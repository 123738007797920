import { lazy } from 'react'

import { RouteObject } from '@/router/types'

const Points: Array<any> = [
  {
    path: '/recruit',
    meta: {
      title: '招聘'
    },
    children: [
      {
        path: '/recruit/needs',
        element: lazy(() => import(/* webpackChunkName: "needsTabs" */ '@/pages/recruit/needs')),
        meta: {
          requiresAuth: true,
          title: '招聘需求',
          key: 'recruitNeeds'
        }
      }
    ]
  }
]

export default Points
