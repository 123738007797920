import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Loading, Toast } from 'antd-mobile'
import { Provider, useLocalObservable } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useContext, useState } from 'react'
import { AliveScope } from 'react-activation'

import {
  // BrowserRouter,
  // HashRouter,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom'

import { NetworkStatus } from '@/components'
import Routers from '@/router'
import { storeWithContext } from '@/store'
import { commonNav } from '@/utils'
// import dictStore from '@/store/global/dictStore'
import { getToken, history, hybStorage, initApiDevEnv } from '@/utils'
import getQuery from '@/utils/getQuery'

import './app.less'
import { EventManagerProvider } from './components/EventManagerProvider'

// TODO hash  /  history router
// const HistroyRouter = __HASH_MODE__ ? HashRouter : BrowserRouter
declare const __ROUTE_PREFIX__: string

function App() {
  const store = useContext(storeWithContext)
  // const dict_store = useLocalObservable(() => dictStore)
  const [finished, setFinished] = useState(false)
  const init = async () => {
    const token = decodeURIComponent(getQuery('token') || '')
    const hyb_dev_env = decodeURIComponent(getQuery('hyb_dev_env') || '')
    const corpId = decodeURIComponent(getQuery('corpId') || '')
    const agentId = decodeURIComponent(getQuery('agentId') || '')
    initApiDevEnv(hyb_dev_env)
    console.log('inityixia:', corpId)
    if (token) {
      // for 微信小程序
      await store.urlLogin()
    } else if (corpId && !getToken()) {
      // for 钉钉、飞书、企微
      await store.webviewLogin(corpId, false, hyb_dev_env, agentId)
      // location.href = `http://192.168.148.126:4000/clock?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcm9kdWN0SWQiOiIxMjAwMiIsIm9wZW5JZCI6IjAiLCJpc3MiOiJzdGFyX2p3dCIsInRlbmFudElkIjoiMTExMTEiLCJlbXBsb3llZUlkIjoiMTcxNjAwNDk0NDgwNjY0NTc2MCIsImV4cCI6MTcyNzQzMjQxNCwidXNlck5hbWUiOiLmnajkuJbosaoiLCJlbnYiOiJxYSIsInVzZXJJZCI6IjE3MTYwMDQ5NDQ4MzE4MTE1ODQiLCJub25jZSI6IjE4Mjg3MjYxODAwMzYxNjE1MzYifQ.9dh_OZoXvGcSOKoov_awJVis9CkYvybemH8NXcMEKns&mobile=08G3lrZfmIqntexFB4oXeg%3D%3D&unionid=oVBNl6sQLhXPEm0mSiqnjlST6zWY&phone=15814496856&tenant_id=11111#wechat_redirect`
    }
    //  else if (getToken() && hybStorage.get('corp_id')) {
    //   // 钉钉token过期自动替换
    //   await store.webviewLogin(hybStorage.get('corp_id'), true, hyb_dev_env)
    // }
    setFinished(true)
  }

  useEffect(() => {
    // 使用 performance.navigation.type
    if (performance && performance.navigation && performance.navigation.type) {
      switch (performance.navigation.type) {
        case 0:
          console.log('页面是正常加载')
          break
        case 1:
          console.log('页面是通过 location.reload() 刷新')
          // commonNav.navigateBack()
          break
        case 2:
          console.log('页面是通过后退/前进按钮加载')
          break
        default:
          console.log('未知的加载类型')
      }
    }
    init()
  }, [])

  const queryClient = new QueryClient()

  if (!finished) {
    return <Loading className='init-loading' />
  }
  console.log('运行app...')
  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter history={history as any} basename={__ROUTE_PREFIX__}>
        <AliveScope>
          <Provider store={store}>
            <EventManagerProvider>
              <NetworkStatus />
              <Routers />
            </EventManagerProvider>
          </Provider>
        </AliveScope>
      </HistoryRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}

export default observer(App)
