import { lazy } from 'react'

const My: Array<any> = [
  {
    path: '/my',
    meta: {
      title: '我的'
    },
    children: [
      {
        path: '/my/useWebSide',
        element: lazy(
          () => import(/* webpackChunkName: "my/useWebSide" */ '@/pages/my/useWebSide')
        ),
        meta: {
          requiresAuth: true,
          title: '去使用网页端',
          key: 'useWebSide'
        }
      },
      {
        path: '/my/webviewUrl',
        element: lazy(
          () => import(/* webpackChunkName: "my/webviewUrl" */ '@/pages/my/webViewUrl')
        ),
        meta: {
          requiresAuth: true,
          title: '协议',
          key: 'webviewUrl'
        }
      },
      {
        path: '/my/blankPage',
        element: lazy(() => import(/* webpackChunkName: "my/blankPage" */ '@/pages/my/blankPage')),
        meta: {
          requiresAuth: true,
          title: '开发中',
          key: 'blankPage'
        }
      }
    ]
  }
]

export default My
